@import "../../scss/breakpoints";
.MobileHeader{
  display: none;
  top: 0;
  width: 100%;
  pointer-events: bounding-box;
  z-index: 99;
  background-color: rgba(0,0,0,.5);
  justify-content: space-between;
  @include media-breakpoint-down(sm){
    display: flex;
  }
  .Button{
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    margin: .3rem;
  }
  .Title{
    margin: auto;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
