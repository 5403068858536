@import '../../scss/breakpoints';

.SearchBar{
  border-bottom: 1px solid var(--bs-secondary);
  padding: .5rem;

}
.List{
  overflow-x: hidden;
  overflow-y: auto;
  .File{
    padding: .5rem 1rem;
    background: var(--bs-dark);
    &.Active{
      background: var(--bs-success);
    }
    &.Deleted{
      background: var(--bs-secondary);
    }
    .Icons{
      display: flex;
    }
    .Name{
      white-space: nowrap;
      width: 15rem;
      text-overflow: ellipsis;
      margin-right: .5rem;

      overflow: hidden;
    }
  }
}
.Content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex: 1;
  .RightPanel{
    width: 15rem;
  }
}


.MediaList{
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  min-width: 0;
  flex: 1 1 0;
  padding: .5rem;
  min-height: 225px;
}
