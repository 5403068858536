@import "../../../scss/breakpoints";
.AvatarTag{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: .5rem;
  width: 5rem;
  .Avatar{
    aspect-ratio: 1/1;
    height: 5rem;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #6b6b6b;
    margin-bottom: .5rem;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .Name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}