@import '../scss/breakpoints';

.Form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .Logo{
    width: 100%;
    padding-bottom: 2rem;
  }
}