@import '../../scss/breakpoints';

.StatusBar {
  height: 3rem;
  align-items: center;
  display: flex;
  background: #181818;
  border-bottom: 1px solid #BDBDBD;
  padding: 8px 16px;
  justify-content: space-between;
  position: relative;
  .Logo{
    display: flex;
    position: relative;
    height: 2rem;
    img{
      width:100%;
      height: 100%;
    }
  }
  @include media-breakpoint-down(sm){
    display: none;
  }
}