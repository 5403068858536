@import '../../scss/breakpoints';

.SearchBar{
  border-bottom: 1px solid var(--bs-secondary);
  padding: .5rem;

}
.List{
  overflow-x: hidden;
  overflow-y: auto;
  .File{
    padding: .5rem 1rem;
    background: var(--bs-dark);
    &.Active{
      background: var(--bs-success);
    }
    &.Deleted{
      background: var(--bs-secondary);
    }
    .Icons{
      display: flex;
    }
    .Name{
      white-space: nowrap;
      width: 15rem;
      text-overflow: ellipsis;
      margin-right: .5rem;

      overflow: hidden;
    }
  }
}
.Content{
  display: flex;
  flex: 1;
  .Player{
    flex: 1;
    height: 100%;
  }
  .RightPanel{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .Title{
      user-select: text;
    }
    .ActorList{
      display: flex;
      flex-direction: column;
      padding-top: .5rem;


      .List {
        padding-top: .5rem;
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        min-width: 0;
        flex: 1 1 0;
      }
    }
  }
}

