@import "../../scss/breakpoints";
.MobileNavBar{
  display: none;
  position: relative;
  bottom: 0;
  width: 100%;
  pointer-events: bounding-box;
  background-color: #181818;
  z-index: 11;
  border-top: 1px solid #000;
  @include media-breakpoint-down(sm){
    display: block;
  }
  .NavLink{
    padding: 0 .5rem;
    .Name{
      font-size: 0.725rem;
      font-weight: lighter;
    }
  }
}