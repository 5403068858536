@import '../../scss/breakpoints';

.Media{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1rem;
  .Thumbnail{
    cursor: pointer;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: .5rem;
    @include media-breakpoint-down(sm) {
      border-radius: 0;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .Cover{
      top: 0;
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Bottom{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .Info{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .Like{
            font-size: .75rem;
            font-weight: bold;
            border-radius: .5rem;
            padding:  .1rem .4rem;
            background: rgba(0, 0, 0, .5);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .7rem .5rem;
            @include media-breakpoint-down(sm) {
              margin: 0;
            }
          }
          .Hate{
            font-size: .75rem;
            font-weight: bold;
            border-radius: .5rem;
            padding:  .1rem .4rem;
            background: rgba(0, 0, 0, .5);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .7rem .5rem;
            @include media-breakpoint-down(sm) {
              margin: 0;
            }
          }
          .Duration{
            font-size: .75rem;
            font-weight: bold;
            border-radius: .5rem;
            padding:  .1rem .4rem;
            background: rgba(0, 0, 0, .5);
            margin: .7rem .5rem;
          }
        }
        .Progress{
          position: absolute;
          display: flex;
          height: 4px;
          background: #717171;
          width: 100%;
          bottom: 0;
          z-index: 1;
          .Ing{
            background: #fe1000;
            height: 4px;
          }
        }

      }
    }
  }
  .Title{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: .2rem;
    font-size: 1rem;
    font-weight: 700;
    @include media-breakpoint-down(sm) {
      padding-left: .5rem;
    }
  }
  .Info{
    font-size: .75rem;
    font-weight: 500;
    color: rgb(170,170,170);
    @include media-breakpoint-down(sm) {
      padding-left: .5rem;
    }
  }
}